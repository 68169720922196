import React from 'react';
import cls from 'classnames';

import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { useCurrentStateAndParams, useRouter } from '@wix/tpa-router/react';

import { Tabs } from 'wui/Tabs';

import classes from './Navigation.scss';

export function Navigation() {
  const router = useRouter();
  const { state } = useCurrentStateAndParams();
  const { isRTL, isMobile } = useEnvironment();
  const { t } = useTranslation();

  return (
    <Tabs
      skin="clear"
      items={[
        {
          id: 'members.groups.my-groups', // state name
          title: t('groups-in-ma.navigation.groups'),
        },
        {
          id: 'members.groups.my-posts', // state name
          title: t('groups-in-ma.navigation.posts'),
        },
      ]}
      data-hook="tabsHook"
      aria-labelledby="group-title"
      alignment={isRTL ? 'right' : 'left'}
      variant={isMobile ? 'fullWidth' : 'fit'}
      onChange={handleTabClick}
      value={state.name || ''}
      className={cls(classes.root, { [classes.mobile]: isMobile })}
    />
  );

  function handleTabClick(stateName: string) {
    router.go(stateName);
  }
}

Navigation.displayName = 'Navigation';
