import type { CustomCssVarsFn } from '@wix/yoshi-flow-editor';

import * as wui from 'settings/wui';
import * as feed from 'settings/feed';
import { getFeedCssVars } from 'settings/feed/cssVars';
import * as groups from 'settings/groups';
import { getGroupsCssVars } from 'settings/groups/cssVars';
import { application } from 'settings/common';
import { createStylesParam, StyleParamType } from '@wix/tpa-settings';

export default {
  ...wui.styles,
  ...feed.styles,
  ...groups.styles,
  ...application.styles,
  vSpacing: createStylesParam('vSpacing', {
    type: StyleParamType.Number,
    getDefaultValue: () => 20,
  }),
};

// noinspection JSUnusedGlobalSymbols
export const customCssVars: CustomCssVarsFn = (params) => ({
  ...getGroupsCssVars(params),
  ...getFeedCssVars(params),
});
