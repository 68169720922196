import React from 'react';
import { useSettings } from '@wix/tpa-settings/react';

import { Typography } from 'wui/Typography';
import { Box } from 'wui/Box';

import settingsParams from '../../settingsParams';
import { Navigation } from './Navigation';

export function Header() {
  const settings = useSettings();

  return (
    <Box direction="vertical" gap="SP3">
      <Box direction="vertical">
        <Typography variant="h2-28">
          {settings.get(settingsParams.titleText)}
        </Typography>
        <Typography variant="p2-16">
          {settings.get(settingsParams.subtitleText)}
        </Typography>
      </Box>
      <Navigation />
    </Box>
  );
}

Header.displayName = 'Header';
