import React from 'react';
import { WidgetProps, useEnvironment } from '@wix/yoshi-flow-editor';

import type { IControllerProps } from 'controller/types';

import { Container } from 'wui/Container';

import { SocialGroupsApp } from 'common/components/SocialGroupsApp';

import { Widget } from './Widget';
import styles from './index.scss';

function MemebersAreaGroups(props: WidgetProps<IControllerProps>) {
  const { isMobile } = useEnvironment();

  return (
    <SocialGroupsApp {...props}>
      <Container fluid={isMobile} className={styles.container}>
        <Widget />
      </Container>
    </SocialGroupsApp>
  );
}

MemebersAreaGroups.displayName = 'MemebersAreaGroupsWidget';

export default MemebersAreaGroups;
